import { FC } from 'react';

import { useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';

import { useSelector } from 'src/hooks/useSelector';
import { CriteriaKey } from 'src/types/search/common/criteria';

import SearchOrder from 'src/components/Employer/VacancySearch/FiltersForm/SearchOrder';
import SuggestForm from 'src/components/Employer/VacancySearch/FiltersForm/SuggestForm';
import VacancyCount from 'src/components/Employer/VacancySearch/FiltersForm/VacancyCount';

import styles from './filter-form.less';

const FiltersForm: FC = () => {
    const { isMobile } = useBreakpoint();
    const vacanciesCount = useSelector((state) => state.activeEmployerVacancyCount);
    const isLoading = useSelector(({ employerVacancySearch }) => employerVacancySearch.isLoading);
    const resultsFound = useSelector(({ employerVacancySearch }) => employerVacancySearch.resultsFound);
    const hasTextFilter = useSelector((state) => !!state.employerVacancySearch.criteria?.[CriteriaKey.Text]);
    const hasUsedFilters = useSelector(({ employerVacancySearch }) => !!employerVacancySearch.totalUsedFilters);

    if (!vacanciesCount || (!resultsFound && !hasTextFilter && !hasUsedFilters)) {
        return null;
    }

    return (
        <>
            <SuggestForm />
            <VSpacing gteM={24} default={16} />
            {!isLoading && (
                <>
                    <div className={styles.filtersFormOrderBy}>
                        {!isMobile && <SearchOrder />}
                        <div className={styles.filtersFormDummy} />
                        <VacancyCount />
                    </div>
                    <VSpacing gteM={12} default={16} />
                </>
            )}
        </>
    );
};

export default FiltersForm;
