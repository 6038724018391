import { PropsWithChildren } from 'react';

import { Action, BottomSheet, BottomSheetFooter, NavigationBar } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import useToggleSearchPreference from 'src/components/SearchSorts/hooks/useToggleSearchPreference';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import CounterButton from 'src/components/Search/Common/Filters/MobileForm/CounterButton';
import ResetButton from 'src/components/Search/Common/Filters/MobileForm/ResetButton';

const TrlKeys = {
    title: 'search.filters.title',
};

const FormMobile: TranslatedComponent<PropsWithChildren> = ({ children, trls }) => {
    const showSearchPreference = useSelector(({ searchPreference }) => searchPreference.isShown);
    const toggleSearchPreference = useToggleSearchPreference();

    return (
        <BottomSheet
            allowScrollWhileFocused
            visible={showSearchPreference}
            onClose={() => toggleSearchPreference(false)}
            header={
                <NavigationBar
                    title={trls[TrlKeys.title]}
                    showDivider="with-scroll"
                    right={<Action icon={CrossOutlinedSize24} onClick={() => toggleSearchPreference(false)} />}
                />
            }
            footer={
                <BottomSheetFooter>
                    <CounterButton />
                    <ResetButton />
                </BottomSheetFooter>
            }
            showDivider="with-scroll"
        >
            {children}
        </BottomSheet>
    );
};

export default translation(FormMobile);
