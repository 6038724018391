import { KeyboardEventHandler, FocusEventHandler, useCallback, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';

import vacancySearchLineFormFieldTouch, {
    HhtmSource,
} from '@hh.ru/analytics-js-events/build/xhh/common/vacancy_search/vacancy_search_line_form_field_touch';
import { useBreakpoint, SearchInput } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { INPUT_ID } from 'src/components/A11y/A11yConstants';
import SuggestWithDefaultErrorPlaceholder from 'src/components/SuggestWithDefaultErrorPlaceholder';
import { SuggestItem } from 'src/components/SupernovaSearch/SearchSuggest/types';
import useProvider from 'src/components/SupernovaSearch/SearchSuggest/useProvider';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { setSearchLineText } from 'src/models/employerVacancySearch';
import { resetEmployerVacancyTemplateTextCriteria } from 'src/models/employerVacancyTemplateFilter';
import { SupernovaSearchName } from 'src/models/supernovaSearchName';
import { CriteriaKey } from 'src/types/search/common/criteria';

const TrlKeys = {
    submit: 'supernova.search.submit',
    tooLongQuery: 'query.length.moreThanMax',
    placeholder: 'employer.vacancy.search.text.placeholder',
    submitVacancies: 'supernovaSearch.bottomSheet.title.vacancies',
};

const MAX_QUERY_LENGTH = 3000;

const SearchSuggest: TranslatedComponent<{ onFormSubmit: () => void }> = ({ trls, onFormSubmit }) => {
    const dispatch = useDispatch();
    const hhtmSource = useSelector((state) => state.analyticsParams.hhtmSource as HhtmSource);
    const searchLineText = useSelector((state) => state.employerVacancySearch.searchLineText);
    const templateFilterText = useSelector((state) => state.employerVacancyTemplateFilter[CriteriaKey.Text]);
    const searchCriteriaText = useSelector(
        ({ employerVacancySearch }) => employerVacancySearch.criteria?.[CriteriaKey.Text]
    );
    const queryText = searchLineText ?? searchCriteriaText ?? '';
    const inputRef = useRef<HTMLInputElement>(null);
    const dataProvider = useProvider(SupernovaSearchName.Vacancies);
    const { isMobile } = useBreakpoint();

    const inputLabel = trls[TrlKeys.submitVacancies];

    const updateText = useCallback(
        (text: string, submit?: () => void) => {
            templateFilterText && dispatch(resetEmployerVacancyTemplateTextCriteria());
            dispatch(setSearchLineText(text));
            submit && setTimeout(() => submit());
        },
        [dispatch, templateFilterText]
    );

    const handleItemSelect = useCallback(
        (_: string, item?: SuggestItem): boolean => {
            if (item) {
                updateText(item.text, onFormSubmit);
            }
            return true;
        },
        [onFormSubmit, updateText]
    );

    const handleMobileKeyboardSubmit: KeyboardEventHandler<HTMLInputElement> = useCallback(
        (event) => {
            // Проверяем на isMobile, чтобы в десктопной версии не вызывать триггер формы дважды.
            // Там срабатывает нативный сабмит внутри формы.
            if (event.key === 'Enter' && isMobile) {
                onFormSubmit();
            }
        },
        [isMobile, onFormSubmit]
    );

    const onFocusInput: FocusEventHandler<HTMLInputElement> = useCallback(() => {
        vacancySearchLineFormFieldTouch({ hhtmSource });
    }, [hhtmSource]);

    const inputProps = useMemo(() => {
        return {
            type: undefined,
            onFocus: onFocusInput,
            id: INPUT_ID,
            name: CriteriaKey.Text,
            value: queryText,
            placeholder: trls[TrlKeys.placeholder],
            onKeyDown: handleMobileKeyboardSubmit,
            ref: inputRef,
            onChange: updateText,
            clearable: true,
            maxLength: MAX_QUERY_LENGTH,
            autoComplete: 'off',
            'aria-label': inputLabel,
            'data-qa': 'employer-vacancy-search-input',
        };
    }, [onFocusInput, queryText, trls, handleMobileKeyboardSubmit, updateText, inputLabel]);

    return (
        <SuggestWithDefaultErrorPlaceholder
            inputValue={queryText}
            onSelectValidator={handleItemSelect}
            dataProvider={dataProvider}
            input={{
                component: SearchInput,
                props: inputProps,
            }}
            navigationBarProps={{
                title: inputLabel,
            }}
        />
    );
};

export default translation(SearchSuggest);
