import { FC } from 'react';

import { Layer } from '@hh.ru/magritte-ui';

import { usePageTabsContext, EmployerPageTab } from 'src/components/Employer/PageTabs/PageTabsContext';
import MagritteWrapper from 'src/components/MagritteWrapper/MagritteWrapper';
import EmployerVacancySearchScrollToTop from 'src/components/SearchScrollToTop/EmployerVacancy';
import { useSearchSettings } from 'src/hooks/search/useSearchSettings';
import { useSelector } from 'src/hooks/useSelector';

import styles from './search-footer-button.less';

const SearchFooterButton: FC = () => {
    const hasChameleon = useSelector(({ hasChameleon }) => hasChameleon);
    const totalResults = useSelector((state) => state.employerVacancySearch.resultsFound ?? 0);
    const { currentTab } = usePageTabsContext();
    const { isEmployerViewPage } = useSearchSettings();

    if (!isEmployerViewPage || (!(currentTab === EmployerPageTab.Vacancies && totalResults > 3) && !hasChameleon)) {
        return null;
    }

    return (
        <Layer layer="alpha">
            <div className={styles.searchFooterButton}>
                <MagritteWrapper isEnabled>
                    <EmployerVacancySearchScrollToTop />
                </MagritteWrapper>
            </div>
        </Layer>
    );
};

export default SearchFooterButton;
