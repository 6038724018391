import { useDispatch } from 'react-redux';

import { Button } from '@hh.ru/magritte-ui';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { usePush } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useSetEmployerVacancyListDefault } from 'src/api/employerVacancySearch/useSetEmployerVacancies';
import translation from 'src/components/translation';
import { useSearchSettings } from 'src/hooks/search/useSearchSettings';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    text: 'search.filters.reset',
};

const searchCountsAction = makeSetStoreField('searchCounts');

const ResetButton: TranslatedComponent = ({ trls }) => {
    const { searchActionPath, isVacancySearchType, isEmployerViewPage } = useSearchSettings();
    const searchCounts = useSelector((state) => state.searchCounts);
    const { setEmployerVacancyListDefault } = useSetEmployerVacancyListDefault();
    const dispatch = useDispatch();
    const push = usePush();

    return (
        <Button
            style="accent"
            mode="secondary"
            onClick={() => {
                if (isEmployerViewPage) {
                    setEmployerVacancyListDefault();
                    return;
                }
                if (isVacancySearchType) {
                    dispatch(searchCountsAction({ isLoad: true, value: searchCounts.value }));
                }
                push(searchActionPath);
            }}
            stretched
        >
            {trls[TrlKeys.text]}
        </Button>
    );
};

export default translation(ResetButton);
